import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { storage, db, auth } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc, getDoc, addDoc, collection } from 'firebase/firestore';
import { getIdToken } from 'firebase/auth';
import { CheckCircleIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { Disclosure, Menu } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import profileImage from '../images/images.png';
import logo from '../images/logo.png';

const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
};

const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
  { name: 'Reports', href: '#', current: false },
];

const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const requiredDocuments = [
  {
    DocumentID: 1,
    Name: "Personal Financial Statement",
    Description: "Upload your Personal Financial Statement.",
    RequiredDocuments: 1,
    Individual: true,
    Labels: ["Personal Financial Statement"],
    loanPurpose: ["Acquisition", "workingCapital", "workingCapital-startup"]
  },
  {
    DocumentID: 6,
    Name: "Business Tax Returns",
    Description: "Upload the last 3 years of business tax returns.",
    RequiredDocuments: 3,
    Individual: false,
    Labels: [
      "Business Tax Return - Current Year",
      "Business Tax Return - Last Year",
      "Business Tax Return - 2 Years Ago"
    ],
    loanPurpose: ["Acquisition", "workingCapital"]
  }
];

const SharedUploadPage = () => {
  const { loanId } = useParams();
  const [uploading, setUploading] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [creditScore, setCreditScore] = useState('');
  const [yearlyProfit, setYearlyProfit] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [businessSummary, setBusinessSummary] = useState('');
  const [workingCapitalUse, setWorkingCapitalUse] = useState('');
  const [loanAmount, setLoanAmount] = useState('');
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);
  const [step, setStep] = useState(0);

  const people = [
    {
      name: 'Tom Cook',
      title: 'Loan Officer',
      role: 'Member',
      email: 'tom@example.com',
      telephone: '+1 (555) 123-4567',
      imageUrl: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  ];

  useEffect(() => {
    const fetchLoanApplicationData = async () => {
      if (loanId) {
        const loanDoc = await getDoc(doc(db, 'loanApplications', loanId));
        if (loanDoc.exists()) {
          const loanData = loanDoc.data();
          setUploadedFiles(loanData.uploadedDocuments || {});
          setFirstName(loanData.firstName || '');
          setLastName(loanData.lastName || '');
          setCreditScore(loanData.creditScore || '');
          setYearlyProfit(loanData.yearlyProfit || '');
          setBusinessName(loanData.businessName || '');
          setBusinessSummary(loanData.businessSummary || '');
          setWorkingCapitalUse(loanData.workingCapitalUse || '');
          setLoanAmount(loanData.loanSize || '');
        }
      }
    };
    fetchLoanApplicationData();
  }, [loanId]);

  const renderForm = () => {
    switch (step) {
      case 0:
        return (
          <div className="space-y-6">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
              <input
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
              <input
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
        );
      case 1:
        return (
          <div className="space-y-6">
            <div>
              <label htmlFor="creditScore" className="block text-sm font-medium text-gray-700">Credit Score</label>
              <input
                type="number"
                id="creditScore"
                value={creditScore}
                onChange={(e) => setCreditScore(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="yearlyProfit" className="block text-sm font-medium text-gray-700">Yearly Profit</label>
              <div className="relative mt-1">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="text"
                  id="yearlyProfit"
                  value={yearlyProfit}
                  onChange={(e) => setYearlyProfit(e.target.value)}
                  required
                  className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  placeholder="0.00"
                />
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="space-y-6">
            <div>
              <label htmlFor="businessName" className="block text-sm font-medium text-gray-700">Business Name</label>
              <input
                type="text"
                id="businessName"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="businessSummary" className="block text-sm font-medium text-gray-700">Business Summary</label>
              <textarea
                id="businessSummary"
                value={businessSummary}
                onChange={(e) => setBusinessSummary(e.target.value)}
                required
                rows={3}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div className="space-y-6">
            <div>
              <label htmlFor="workingCapitalUse" className="block text-sm font-medium text-gray-700">Working Capital Use</label>
              <input
                type="text"
                id="workingCapitalUse"
                value={workingCapitalUse}
                onChange={(e) => setWorkingCapitalUse(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="loanAmount" className="block text-sm font-medium text-gray-700">Loan Amount</label>
              <div className="relative mt-1">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="text"
                  id="loanAmount"
                  value={loanAmount}
                  onChange={(e) => setLoanAmount(e.target.value)}
                  required
                  className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  placeholder="0.00"
                />
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const loanRef = doc(db, 'loanApplications', loanId);
    await updateDoc(loanRef, {
      firstName,
      lastName,
      creditScore,
      yearlyProfit,
      businessName,
      businessSummary,
      workingCapitalUse,
      loanSize: loanAmount,
      personalGuarantors: [{ firstName, lastName }],
      lastUserUpdate: new Date(),
      loanStructure: {
        sba7aLoan: "100",
        additionalNotes: ""
      }
    });
    setShowDocumentUpload(true);
  };

  const handleFileUpload = async (e, docId, label) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(prev => ({ ...prev, [`${docId}-${label}`]: true }));

    try {
      const storageRef = ref(storage, `loanDocuments/${loanId}/${docId}/${label}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed',
        (snapshot) => {
          // Progress function
        },
        (error) => {
          console.error("Upload error:", error);
          setUploading(prev => ({ ...prev, [`${docId}-${label}`]: false }));
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const now = new Date().toISOString();

          // Create document metadata
          const documentMetadata = {
            fileName: file.name,
            fileUrl: downloadURL,
            uploadedAt: now,
            userId: auth.currentUser.uid,
            documentName: requiredDocuments.find(doc => doc.DocumentID === docId)?.Name || '',
            loanApplicationId: loanId,
            ownerId: auth.currentUser.uid,
            label: label,
          };

          // Add document metadata to Firestore
          const documentRef = await addDoc(collection(db, 'documentMetadata'), documentMetadata);
          const metadataId = documentRef.id; // Get the Firestore-generated document ID

          // Update documentMetadata with the Firestore-generated ID
          documentMetadata.documentId = metadataId;

          // Update loan application
          const loanRef = doc(db, 'loanApplications', loanId);
          await updateDoc(loanRef, {
            [`uploadedDocuments.${docId}-${label}`]: {
              url: downloadURL,
              name: file.name,
              uploadedAt: now,
              metadataId: metadataId
            }
          });

          setUploadedFiles(prev => ({
            ...prev,
            [`${docId}-${label}`]: {
              url: downloadURL,
              name: file.name,
              uploadedAt: now,
              metadataId: metadataId
            }
          }));

          setUploading(prev => ({ ...prev, [`${docId}-${label}`]: false }));

          // Process document with OCR
          const idToken = await getIdToken(auth.currentUser);
          const requestBody = {
            fileUrl: downloadURL,
            metadata: documentMetadata,
          };

          const response = await fetch('https://sba-30d9855b23ef.herokuapp.com/process-document', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${idToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          });

          if (response.ok) {
            console.log(`Document uploaded and processed successfully for docId: ${docId}, label: ${label}`);
            const responseData = await response.json();
            console.log('OCR Response data:', JSON.stringify(responseData, null, 2));
          } else {
            console.error(`Error processing document for docId: ${docId}, label: ${label}`);
            const errorData = await response.text();
            console.error('Error response:', errorData);
          }

          console.log(`Document uploaded and metadata created successfully for ${label}`);
        }
      );
    } catch (error) {
      console.error("Error in handleFileUpload:", error);
      setUploading(prev => ({ ...prev, [`${docId}-${label}`]: false }));
    }
  };

  const allDocumentsUploaded = requiredDocuments.every(doc => 
    doc.Labels.every(label => uploadedFiles[`${doc.DocumentID}-${label}`])
  );

  const person = {
    name: 'Max Krakow',
    role: 'Application Manager',
    title: 'CEO, Lended',
    email: 'max@lended.ai',
    telephone: '+1 (914) 319-7160',
    imageUrl: profileImage,
  };

  if (!showDocumentUpload) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="w-full max-w-6xl bg-white rounded-2xl shadow-xl overflow-hidden">
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 bg-blue-600 p-12 text-white flex flex-col justify-center">
              <h2 className="text-4xl font-bold mb-4">Loan Information</h2>
              <p className="text-xl mb-8">Complete your application</p>
              <div className="bg-white bg-opacity-20 rounded-lg p-6">
                <p className="text-2xl font-semibold mb-2">Loan Amount</p>
                <p className="text-4xl font-bold">${loanAmount}</p>
              </div>
            </div>
            <div className="w-full md:w-1/2 p-12">
              <div className="mb-8">
                <img
                  className="h-12 w-auto mb-6"
                  src={logo}
                  alt="Your Company"
                />
                <h2 className="text-3xl font-bold text-gray-900 mb-2">
                  Complete Your Application
                </h2>
                <p className="text-lg text-gray-600">
                  Please provide some additional information about your business.
                </p>
              </div>
              <form onSubmit={handleSubmit} className="space-y-6">
                {renderForm()}
                <div className="flex justify-between">
                  {step > 0 && (
                    <button
                      type="button"
                      onClick={handlePrevious}
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      Previous
                    </button>
                  )}
                  {step < 3 ? (
                    <button
                      type="button"
                      onClick={handleNext}
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Continue to Document Upload
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-full">
      <div className="bg-blue-600 pb-32">
        <header className="py-10">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-2xl sm:text-3xl font-bold tracking-tight text-white text-center">Required Documents to Receive a Term Sheet</h1>
          </div>
        </header>
      </div>

      <main className="-mt-32">
        <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
          <div className="rounded-lg bg-white px-4 py-6 shadow sm:px-6">
            <div className="sm:flex sm:items-center sm:justify-between mb-6">
              <div>
                <h2 className="text-lg font-semibold text-gray-900">{firstName} {lastName}'s Application</h2>
                <p className="mt-2 text-sm text-gray-700">
                  Loan Amount: ${loanAmount} | Purpose: Working Capital
                </p>
              </div>
              <div className="mt-4 sm:mt-0">
                {allDocumentsUploaded ? (
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                    All Documents Uploaded
                  </span>
                ) : (
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                    Documents Pending
                  </span>
                )}
              </div>
            </div>
            <div className="mt-8 overflow-hidden">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Document</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">Description</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">Status</th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Upload</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {requiredDocuments.flatMap(doc => 
                    doc.Labels.map(label => (
                      <tr key={`${doc.DocumentID}-${label}`}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{label}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">{doc.Description}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                          {uploadedFiles[`${doc.DocumentID}-${label}`] ? (
                            <span className="inline-flex items-center text-green-600">
                              <CheckCircleIcon className="h-5 w-5 mr-1.5" aria-hidden="true" />
                              Uploaded
                            </span>
                          ) : (
                            'Pending'
                          )}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          {!uploadedFiles[`${doc.DocumentID}-${label}`] && (
                            <label
                              htmlFor={`file-${doc.DocumentID}-${label}`}
                              className="text-blue-600 hover:text-blue-900 cursor-pointer"
                            >
                              {uploading[`${doc.DocumentID}-${label}`] ? 'Uploading...' : 'Upload'}
                              <input
                                id={`file-${doc.DocumentID}-${label}`}
                                name={`file-${doc.DocumentID}-${label}`}
                                type="file"
                                className="sr-only"
                                onChange={(e) => handleFileUpload(e, doc.DocumentID, label)}
                                disabled={uploading[`${doc.DocumentID}-${label}`]}
                              />
                            </label>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            <div className="mt-8">
              <ul role="list" className="space-y-4">
                {[person].map((person) => (
                  <li
                    key={person.email}
                    className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow-md mb-4 border border-gray-300"
                  >
                    <div className="flex w-full items-center justify-between space-x-6 p-4 sm:p-6">
                      <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                          <h3 className="truncate text-sm font-medium text-gray-900">
                            {person.name}
                          </h3>
                          <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            {person.role}
                          </span>
                        </div>
                        <p className="mt-1 truncate text-sm text-gray-500">{person.title}</p>
                      </div>
                      <img
                        className="h-10 w-10 flex-shrink-0 rounded-lg"
                        src={person.imageUrl}
                        alt=""
                      />
                    </div>
                    <div>
                      <div className="-mt-px flex divide-x divide-gray-200">
                        <div className="flex w-0 flex-1">
                          <a
                            href={`mailto:${person.email}`}
                            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                          >
                            <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            Email
                          </a>
                        </div>
                        <div className="-ml-px flex w-0 flex-1">
                          <a
                            href={`tel:${person.telephone}`}
                            className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                          >
                            <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            Call
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SharedUploadPage;
