import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, collection, query, where, getDocs, addDoc, updateDoc } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import PrequalifyBusiness from './prequalifyBusiness';
import { Menu, Transition } from '@headlessui/react';
import { CheckCircleIcon, ClockIcon, BanknotesIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';
import { getStorage, ref as storageRef, getDownloadURL, listAll } from 'firebase/storage';

const statuses = {
  Complete: 'text-green-700 bg-green-50 ring-green-600/20',
  'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
  'Not started': 'text-red-700 bg-red-50 ring-red-600/20',
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const navigation = [
  { id: 'applications', name: 'Applications' },
];

const statusTabs = [
  { name: 'All', status: null },
  { name: 'Not started', status: 'Not started' },
  { name: 'In progress', status: 'In progress' },
  { name: 'Complete', status: 'Complete' },
  { name: 'Assigned to Bank', status: 'Assigned to Bank' },
  { name: 'Term Sheet Received', status: 'Term Sheet Received' },
];

const BrokerPortal = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [brokerData, setBrokerData] = useState(null);
  const [applications, setApplications] = useState([]);
  const [workingCapitalApplications, setWorkingCapitalApplications] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState('applications');
  const [selectedApplication, setSelectedApplication] = useState(null);
  const navigate = useNavigate();
  const [expandedApplicationId, setExpandedApplicationId] = useState(null);
  const [termSheets, setTermSheets] = useState({});
  const [selectedTermSheet, setSelectedTermSheet] = useState(null);
  const [bankDetails, setBankDetails] = useState({});
  const [currentStatus, setCurrentStatus] = useState(null);
  const [prequalAmounts, setPrequalAmounts] = useState({});
  const [workingCapitalTermSheets, setWorkingCapitalTermSheets] = useState({});
  const [workingCapitalApplicationsWithTermSheets, setWorkingCapitalApplicationsWithTermSheets] = useState([]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        fetchBrokerData(user.uid);
        fetchApplications(user.uid);
        fetchWorkingCapitalApplications(user.uid);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (currentUser) {
      fetchApplications(currentUser.uid);
      fetchWorkingCapitalApplications(currentUser.uid);
    }
  }, [currentUser]);

  useEffect(() => {
    if (brokerData?.prequalificationApplications?.length > 0) {
      fetchTermSheets(brokerData.prequalificationApplications);
    }
  }, [brokerData?.prequalificationApplications]);

  useEffect(() => {
    if (applications.length > 0) {
      const newPrequalAmounts = {};
      applications.forEach(async (app) => {
        const amount = calculatePrequalAmount(app);
        newPrequalAmounts[app.id] = amount;
        console.log(`Calculated amount for application ${app.id}:`, amount);
        
        // Save the calculated amount to Firestore
        const db = getFirestore();
        const applicationRef = doc(db, 'prequalificationApplications', app.id);
        await updateDoc(applicationRef, { expectedPrequalAmount: amount });
      });
      setPrequalAmounts(newPrequalAmounts);
      console.log('All calculated prequalification amounts:', newPrequalAmounts);
    }
  }, [applications]);

  useEffect(() => {
    if (workingCapitalApplications.length > 0) {
      fetchWorkingCapitalTermSheets(workingCapitalApplications);
    }
  }, [workingCapitalApplications]);

  const fetchBrokerData = async (userId) => {
    const db = getFirestore();
    const brokerDoc = await getDoc(doc(db, 'brokers', userId));
    if (brokerDoc.exists()) {
      const data = brokerDoc.data();
      console.log('Broker data:', data);
      setBrokerData(data);
      if (data.prequalificationApplications && data.prequalificationApplications.length > 0) {
        console.log('Application IDs:', data.prequalificationApplications);
        fetchApplications(userId);
        fetchTermSheets(data.prequalificationApplications);
      } else {
        console.log('No applications found');
        setApplications([]);
        setTermSheets({});
      }
    } else {
      console.log('Broker document not found');
      navigate('/login');
    }
  };

  const fetchApplications = async (userId) => {
    const db = getFirestore();
    const brokerDoc = await getDoc(doc(db, 'brokers', userId));
    if (brokerDoc.exists()) {
      const data = brokerDoc.data();
      if (data.prequalificationApplications && data.prequalificationApplications.length > 0) {
        console.log('Application IDs:', data.prequalificationApplications);
        const applicationsQuery = query(
          collection(db, 'prequalificationApplications'),
          where('__name__', 'in', data.prequalificationApplications)
        );
        const querySnapshot = await getDocs(applicationsQuery);
        const applicationsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log('Fetched applications:', applicationsData);
        setApplications(applicationsData);
      } else {
        console.log('No applications found');
        setApplications([]);
      }
    } else {
      console.log('Broker document not found');
      navigate('/login');
    }
  };

  const fetchWorkingCapitalApplications = async (userId) => {
    const db = getFirestore();
    const userApplicationsRef = collection(db, 'users', userId, 'applications');
    const querySnapshot = await getDocs(userApplicationsRef);
    
    const workingCapitalAppIds = querySnapshot.docs
      .filter(doc => doc.data().status === 'Pre-lender')
      .map(doc => doc.data().applicationID);

    if (workingCapitalAppIds.length > 0) {
      const workingCapitalApps = [];
      for (let i = 0; i < workingCapitalAppIds.length; i += 30) {
        const chunk = workingCapitalAppIds.slice(i, i + 30);
        const appsQuery = query(
          collection(db, 'loanApplications'),
          where('__name__', 'in', chunk)
        );
        const appsSnapshot = await getDocs(appsQuery);
        workingCapitalApps.push(...appsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      }
      setWorkingCapitalApplications(workingCapitalApps);
    }
  };

  const fetchTermSheets = async (applicationIds) => {
    if (!applicationIds || applicationIds.length === 0) {
      console.log('No application IDs to fetch term sheets');
      setTermSheets({});
      return;
    }

    const db = getFirestore();
    const termSheetsQuery = query(
      collection(db, 'prequalifiedTermSheets'),
      where('prequalificationId', 'in', applicationIds)
    );
    
    try {
      const termSheetsSnapshot = await getDocs(termSheetsQuery);
      const sheets = termSheetsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log('Fetched term sheets:', sheets);
      
      const groupedSheets = sheets.reduce((acc, sheet) => {
        if (!acc[sheet.prequalificationId]) {
          acc[sheet.prequalificationId] = [];
        }
        acc[sheet.prequalificationId].push(sheet);
        return acc;
      }, {});

      console.log('Grouped term sheets:', groupedSheets);
      setTermSheets(groupedSheets);

      // Fetch bank details for each term sheet
      sheets.forEach(sheet => {
        if (sheet.bankId) {
          fetchBankDetails(sheet.bankId);
        }
      });
    } catch (err) {
      console.error('Error fetching term sheets:', err);
      setTermSheets({});
    }
  };

  const fetchBankDetails = async (bankId) => {
    try {
      const db = getFirestore();
      const bankDoc = await getDoc(doc(db, 'banks', bankId));
      if (bankDoc.exists()) {
        setBankDetails(prevDetails => ({
          ...prevDetails,
          [bankId]: bankDoc.data()
        }));
      }
    } catch (err) {
      console.error('Error fetching bank details:', err);
    }
  };

  const fetchWorkingCapitalTermSheets = async (apps) => {
    const storage = getStorage();
    const updatedApps = await Promise.all(apps.map(async (application) => {
      if (application.assignedBankIds && application.assignedBankIds.length > 0) {
        const termSheets = [];
        for (const bankId of application.assignedBankIds) {
          const termSheetsRef = storageRef(storage, `termSheets/${bankId}/${application.id}`);
          try {
            const termSheetsList = await listAll(termSheetsRef);
            const sheets = await Promise.all(termSheetsList.items.map(async (item) => {
              const url = await getDownloadURL(item);
              return { name: item.name, url };
            }));
            termSheets.push(...sheets);
          } catch (error) {
            console.error(`Error fetching term sheets for application ${application.id} and bank ${bankId}:`, error);
          }
        }
        return { ...application, termSheets };
      }
      return application;
    }));

    // Instead of updating state here, update a separate state variable
    setWorkingCapitalApplicationsWithTermSheets(updatedApps.filter(app => app.loanPurpose === 'workingCapital'));
  };

  const handleEdit = (applicationId) => {
    setEditMode(true);
    setSelectedApplication(applications.find(app => app.id === applicationId));
    setExpandedApplicationId(applicationId);
  };

  const handleSave = async (formData) => {
    const db = getFirestore();
    try {
      console.log('Saving application with data:', formData);
      const applicationRef = doc(db, 'prequalificationApplications', formData.id);
      await setDoc(applicationRef, formData, { merge: true });

      const updatedApplications = applications.map(app =>
        app.id === formData.id ? formData : app
      );

      setApplications(updatedApplications);
      setEditMode(false);
      setSelectedApplication(null);
      setExpandedApplicationId(null);

      console.log('Application saved. Fetching updated data...');
      const updatedDoc = await getDoc(applicationRef);
      if (updatedDoc.exists()) {
        console.log('Updated application data:', updatedDoc.data());
      } else {
        console.log('Updated application not found');
      }
    } catch (error) {
      console.error("Error saving prequal business: ", error);
    }
  };

  const handleCancel = () => {
    setEditMode(false);
    setSelectedApplication(null);
    setExpandedApplicationId(null);
  };

  const handleNewBusiness = async () => {
    const db = getFirestore();
    try {
      const newApplication = {
        businessName: 'New Business Application',
        status: 'Not started',
        lastUpdated: new Date().toISOString(),
        brokerId: currentUser.uid,
      };

      const docRef = await addDoc(collection(db, 'prequalificationApplications'), newApplication);
      newApplication.id = docRef.id;

      const updatedBrokerData = {
        ...brokerData,
        prequalificationApplications: [...brokerData.prequalificationApplications, docRef.id],
      };

      await setDoc(doc(db, 'brokers', currentUser.uid), updatedBrokerData, { merge: true });

      setBrokerData(updatedBrokerData);
      setApplications([...applications, newApplication]);
    } catch (error) {
      console.error("Error creating new business application: ", error);
    }
  };

  const handleNewWorkingCapital = async () => {
    const db = getFirestore();
    try {
      const newApplication = {
        businessName: 'New Working Capital Application',
        status: 'Pre-lender',
        createdAt: new Date(),
        updatedAt: new Date(),
        userId: currentUser.uid,
        firstName: '',
        lastName: '',
        email: '',
        number: '',
        businessAge: '',
        businessState: '',
        loanPurpose: 'workingCapital',
        loanSize: '',
        workingLoanType: '',
        industry: '',
        capTable: [],
        ebitda: '',
        revenue: '',
        additionalNotes: '',
        personalGuarantors: [],
        realEstateAppraisal: '0',
        lastUserUpdate: new Date(),
        workingCapitalUse: '',
        yearlyProfit: '',
      };

      const docRef = await addDoc(collection(db, 'loanApplications'), newApplication);
      newApplication.id = docRef.id;

      // Create a reference to this application under the 'applications' node within the 'users' node
      const userApplicationsRef = doc(db, 'users', currentUser.uid, 'applications', docRef.id);
      await setDoc(userApplicationsRef, {
        applicationID: docRef.id,
        status: 'Pre-lender',
      });

      setWorkingCapitalApplications([...workingCapitalApplications, newApplication]);
    } catch (error) {
      console.error("Error creating new working capital application: ", error);
    }
  };

  const getApplicationStatus = (application) => {
    if (application.termSheets && application.termSheets.length > 0) {
      return "Term Sheet Received";
    }
    return application.status;
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case "Term Sheet Received":
        return "bg-green-100 text-green-800 ring-green-600/20";
      case "Complete":
        return "bg-blue-100 text-blue-800 ring-blue-600/20";
      case "In progress":
        return "bg-yellow-100 text-yellow-800 ring-yellow-600/20";
      case "Not started":
        return "bg-gray-100 text-gray-800 ring-gray-600/20";
      case "Assigned to Bank":
        return "bg-purple-100 text-purple-800 ring-purple-600/20";
      default:
        return "bg-gray-100 text-gray-800 ring-gray-600/20";
    }
  };

  const filteredApplications = currentStatus
    ? [...applications, ...workingCapitalApplications].filter(app => getApplicationStatus(app) === currentStatus)
    : [...applications, ...workingCapitalApplications];

  if (!brokerData) {
    return <div>Loading...</div>;
  }
  
  const formatCurrency = (amount) => {
    const num = parseFloat(amount);
    if (isNaN(num)) return 'N/A';
    const millions = num / 1000000;
    return `$${millions.toFixed(1)}mm`;
  };

  const renderBankStats = () => {
    if (!selectedTermSheet) return null;

    const bankId = selectedTermSheet.bankId;
    const bank = bankDetails[bankId] || {};

    const stats = [
      { 
        name: 'Approval Rate', 
        stat: `${bank.approvalPercentage || 'N/A'}%`,
        icon: CheckCircleIcon,
        iconColor: 'text-green-500'
      },
      { 
        name: 'Process Time', 
        stat: `${bank.processTime || 'N/A'} days`,
        icon: ClockIcon,
        iconColor: 'text-yellow-500'
      },
      { 
        name: 'Total Loan Amount', 
        stat: formatCurrency(bank.totalAmount),
        subStat: '(4 years)',
        icon: BanknotesIcon,
        iconColor: 'text-blue-500'
      },
    ];
    return (
        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-3 mb-6">
          {stats.map((item) => (
            <div key={item.name} className="bg-white overflow-hidden shadow rounded-lg p-4 flex items-center">
              <item.icon className={`h-12 w-12 ${item.iconColor} mr-4`} />
              <div className="flex-grow text-right">
                <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                <dd className="mt-1 text-2xl font-semibold text-gray-900">{item.stat}</dd>
                {item.subStat && (
                  <dd className="mt-1 text-sm text-gray-500">{item.subStat}</dd>
                )}
              </div>
            </div>
          ))}
        </dl>
      );
    };
  
    const renderExpectedPrequalAmount = (application) => {
      const expectedAmount = prequalAmounts[application.id];
      return (
        <div className="mb-4 bg-green-50 border border-green-200 rounded-md p-4">
          <div className="flex items-center">
            <BanknotesIcon className="h-6 w-6 text-green-500 mr-2" />
            <div>
              <p className="text-sm font-medium text-green-800">Expected Prequalification Amount</p>
              <p className="text-lg font-semibold text-green-900">
                {typeof expectedAmount === 'number' 
                  ? formatCurrency(expectedAmount) 
                  : expectedAmount}
              </p>
            </div>
          </div>
        </div>
      );
    };

    const renderTermSheets = (applicationId) => {
      console.log('Rendering term sheets for application:', applicationId);
      console.log('Current termSheets state:', termSheets);
      const sheets = termSheets[applicationId] || [];
      if (sheets.length === 0) {
        return (
          <div className="mt-4 bg-gray-50 border border-gray-200 rounded-md p-4">
            <div className="flex items-center">
              <svg className="h-5 w-5 text-gray-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
              <p className="text-sm text-gray-500">No term sheets available for this application.</p>
            </div>
          </div>
        );
      }
    
      return (
        <ul className="divide-y divide-gray-200">
          {sheets.map((sheet) => (
            <li key={sheet.id} className="py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {bankDetails[sheet.bankId]?.bankName || 'Loading bank name...'}
                  </p>
                  <p className="text-sm text-gray-500">
                    {sheet.fileName}
                  </p>
                  <p className="text-sm text-gray-500">
                    Uploaded: {sheet.uploadedAt ? new Date(sheet.uploadedAt.toDate()).toLocaleString() : 'N/A'}
                  </p>
                </div>
                <div>
                  <a
                    href={sheet.fileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                  >
                    View
                  </a>
                </div>
              </div>
            </li>
          ))}
 </ul>
    );
  };

  const renderWorkingCapitalTermSheets = (application) => {
    console.log('Rendering working capital term sheets for application:', application.id);
    // Use the termSheets from workingCapitalApplicationsWithTermSheets
    const appWithTermSheets = workingCapitalApplicationsWithTermSheets.find(app => app.id === application.id);
    const sheets = appWithTermSheets?.termSheets || [];
    console.log('Sheets for this application:', sheets);

    if (sheets.length === 0) {
      return (
        <div className="mt-4 bg-gray-50 border border-gray-200 rounded-md p-4">
          <div className="flex items-center">
            <svg className="h-5 w-5 text-gray-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
            <p className="text-sm text-gray-500">No term sheets available for this application.</p>
          </div>
        </div>
      );
    }

    return (
      <ul className="divide-y divide-gray-200">
        {sheets.map((sheet, index) => (
          <li key={index} className="py-4">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-gray-900 truncate">
                  {sheet.name}
                </p>
                <p className="text-sm text-gray-500">
                  Uploaded: {new Date().toLocaleString()} {/* Replace with actual upload date when available */}
                </p>
              </div>
              <div>
                <a
                  href={sheet.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                >
                  View
                </a>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  const calculatePrequalAmount = (application) => {
    console.log('Calculating prequalification amount for application:', application.id);
    
    if (!application.financialAnalysis) {
      console.log('No financial analysis data available for application:', application.id);
      return "Please upload financial data";
    }

    const years = ['Business Tax Return - 2 Years Ago', 'Business Tax Return - Last Year', 'Business Tax Return - Current Year'];
    
    const nois = years.map(year => {
      const yearData = application.financialAnalysis[year];
      if (!yearData) {
        console.log(`No data available for ${year}`);
        return null;
      }

      const ordinaryBusinessIncome = Number(yearData.ordinaryBusinessIncome) || 0;
      const officerCompensation = Number(yearData.officerCompensation) || 0;
      const depreciation = Number(yearData.depreciation) || 0;
      const interest = Number(yearData.interest) || 0;
      const addBacks = yearData.addBacks ? Object.values(yearData.addBacks).reduce((sum, value) => sum + Number(value), 0) : 0;
      const newOfficerCompensation = Number(yearData.newOfficerCompensation) || 75000;

      const noi = ordinaryBusinessIncome + officerCompensation + depreciation + interest + addBacks - newOfficerCompensation;
      console.log(`NOI for ${year}:`, noi);
      return noi;
    });

    const validNOIs = nois.filter(noi => noi !== null);

    if (validNOIs.length === 0) {
      console.log('No valid NOI calculations available');
      return "Please upload financial data";
    }

    if (validNOIs.length < 3) {
      console.log('Warning: Not all three years of financial data are available');
    }

    const minNOI = Math.min(...validNOIs);
    console.log('Minimum NOI:', minNOI);

    if (minNOI <= 0) {
      console.log('Minimum NOI is not positive, cannot calculate prequalification amount');
      return "Unable to calculate (negative NOI)";
    }

    // Assume a conservative DSCR of 1.25 for prequalification
    const targetDSCR = 1.15;
    
    // Assume a 10-year term and 10.5% interest rate
    const annualInterestRate = 0.105;
    const loanTerm = 10;

    // Calculate maximum supportable annual debt service
    const maxAnnualDebtService = minNOI / targetDSCR;
    console.log('Maximum supportable annual debt service:', maxAnnualDebtService);

    // Calculate loan amount using the debt service constant
    const monthlyRate = annualInterestRate / 12;
    const numberOfPayments = loanTerm * 12;
    const debtServiceConstant = (monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
    const annualDebtServiceConstant = debtServiceConstant * 12;

    const loanAmount = maxAnnualDebtService / annualDebtServiceConstant;
    console.log('Calculated prequalification amount:', loanAmount);

    return Math.round(loanAmount); // Round to nearest whole number
  };

  const renderApplicationButton = (application) => {
    if (application.loanPurpose === 'workingCapital') {
      return (
        <Link
          to={`/shared-upload/${application.id}`}
          className="ml-4 inline-flex items-center px-3 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          Share
        </Link>
      );
    } else {
      return (
        <button
          onClick={() => handleEdit(application.id)}
          className="ml-4 inline-flex items-center px-3 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          View details
        </button>
      );
    }
  };

  return (
    <div className="min-h-full bg-gray-50">
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 pb-32">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between py-5">
            <div className="flex space-x-4">
              {navigation.map((item) => (
                <button
                  key={item.id}
                  onClick={() => setSelectedTab(item.id)}
                  className={classNames(
                    selectedTab === item.id ? 'text-white border-b-2 border-white' : 'text-blue-100',
                    'px-3 py-2 text-sm font-medium hover:text-white focus:outline-none focus:text-white transition duration-150 ease-in-out'
                  )}
                >
                  {item.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
  
      <main className="-mt-32">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="p-6">
              {selectedTab === 'applications' ? (
                <div>
                  <div className="bg-white overflow-hidden sm:rounded-lg shadow-md mb-4">
                    <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                      <div className="flex items-center justify-between">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Business Applications</h3>
                        <div className="space-x-2">
                          <button
                            onClick={handleNewBusiness}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                          >
                            New Application
                          </button>
                          <button
                            onClick={handleNewWorkingCapital}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-150 ease-in-out"
                          >
                            New Working Capital
                          </button>
                        </div>
                      </div>
                      
                      <div className="mt-3 sm:mt-4">
                        <div className="sm:hidden">
                          <label htmlFor="current-status" className="sr-only">
                            Select a status
                          </label>
                          <select
                            id="current-status"
                            name="current-status"
                            value={currentStatus || ''}
                            onChange={(e) => setCurrentStatus(e.target.value || null)}
                            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                          >
                            {statusTabs.map((tab) => (
                              <option key={tab.name} value={tab.status || ''}>{tab.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="hidden sm:block">
                          <nav className="-mb-px flex space-x-8">
                            {statusTabs.map((tab) => (
                              <button
                                key={tab.name}
                                onClick={() => setCurrentStatus(tab.status)}
                                className={classNames(
                                  tab.status === currentStatus
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                                )}
                              >
                                {tab.name}
                              </button>
                            ))}
                          </nav>
                        </div>
                      </div>
                    </div>
                    
                    {/* Applications list */}
                    {filteredApplications.length === 0 ? (
                      <div className="flex items-center justify-center h-64 bg-gray-50">
                        <div className="text-center">
                          <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                          </svg>
                          <h3 className="mt-2 text-lg font-medium text-gray-900">No applications found</h3>
                          <p className="mt-1 text-sm text-gray-500">
                            {currentStatus ? `No applications with status "${currentStatus}"` : 'No applications available'}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="px-4 py-5 sm:p-6">
                        <ul role="list" className="divide-y divide-gray-200">
                          {filteredApplications.map((application) => (
                            <li key={application.id} className="py-4">
                              <div className="flex items-center justify-between">
                                <div className="min-w-0 flex-1">
                                  <div className="flex items-center">
                                    <p className="text-sm font-medium text-gray-900 truncate">
                                      {application.businessName}
                                    </p>
                                    <p className={`ml-2 rounded-full px-2 py-1 text-xs font-medium ${getStatusBadgeClass(getApplicationStatus(application))}`}>
                                      {getApplicationStatus(application)}
                                    </p>
                                    {application.loanPurpose === 'workingCapital' && (
                                      <span className="ml-2 px-2 py-1 text-xs font-medium bg-green-100 text-green-800 rounded-full">
                                        Working Capital
                                      </span>
                                    )}
                                  </div>
                                  <p className="mt-1 text-xs text-gray-500">
                                    Last updated: {new Date(application.lastUserUpdate).toLocaleDateString()}
                                  </p>
                                </div>
                                {renderApplicationButton(application)}
                              </div>
                              <div className="mt-4">
                                {renderExpectedPrequalAmount(application)}
                                <h4 className="text-sm font-medium text-gray-900 mb-2">Term Sheets</h4>
                                {application.loanPurpose === 'workingCapital' 
                                  ? renderWorkingCapitalTermSheets(application)
                                  : renderTermSheets(application.id)
                                }
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-6">Term Sheets</h2>
                  <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
                    <div className="w-full lg:w-1/4 bg-white rounded-xl shadow-lg overflow-hidden">
                      <div className="px-4 py-5 sm:px-6 bg-white border-b border-gray-200">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Available Term Sheets</h3>
                      </div>
                      <div className="border-t border-gray-200">
                        <div className="h-[32rem] overflow-y-auto">
                          {Object.entries(termSheets).flatMap(([prequalId, sheets]) =>
                            sheets.map((sheet) => {
                              const application = applications.find(app => app.id === sheet.prequalificationId);
                              return (
                                <div
                                  key={sheet.id}
                                  className={`p-4 cursor-pointer transition-all duration-200 ${
                                    selectedTermSheet && selectedTermSheet.id === sheet.id
                                      ? 'bg-blue-50 border-l-4 border-blue-500'
                                      : 'hover:bg-gray-50'
                                  }`}
                                  onClick={() => setSelectedTermSheet(sheet)}
                                >
                                  <div className="flex items-center justify-between">
                                    <h4 className="text-lg font-semibold text-gray-900">{application?.businessName || 'Business Name'}</h4>
                                    <span className="px-2 py-1 text-xs font-semibold text-blue-600 bg-blue-100 rounded-full">
                                      {sheet.status || 'Pending'}
                                    </span>
                                  </div>
                                  <div className="mt-2 flex justify-between items-center">
                                    <div className="text-sm text-gray-500">
                                      <p>Bank Name: <span className="font-medium text-gray-700">{bankDetails[sheet.bankId]?.bankName || 'N/A'}</span></p>
                                      <p className="text-sm font-semibold text-green-600">
                                        Expected: {formatCurrency(sheet.expectedPrequalAmount || 5000000)}
                                      </p>
                                    </div>
                                    <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                    </svg>
                                  </div>
                                  <p className="text-xs text-gray-400 mt-2">Uploaded: {sheet.uploadedAt?.toDate().toLocaleString()}</p>
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-full lg:w-3/4 bg-white rounded-xl shadow-md overflow-hidden">
                      {selectedTermSheet ? (
                        <div className="flex flex-col h-[48rem]">
                          <div className="p-6">
                            {renderBankStats()}
                          </div>
                          <div className="flex-grow border rounded-md overflow-hidden mx-6 mb-6">
                            <iframe
                              src={selectedTermSheet.fileUrl}
                              className="w-full h-full border-0"
                              title={`Preview of ${selectedTermSheet.fileName}`}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center h-[48rem] bg-gray-50">
                          <div className="text-center">
                            <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                            </svg>
                            <h3 className="mt-2 text-lg font-medium text-gray-900">No term sheet selected</h3>
                            <p className="mt-1 text-sm text-gray-500">Select a term sheet to view details</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default BrokerPortal;