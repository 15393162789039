


// AuthForm.js
import React, { useState, useEffect, useCallback } from 'react';
import { auth, db } from '../../firebase';
import { createUserWithEmailAndPassword, multiFactor, TotpMultiFactorGenerator, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc, updateDoc, getDoc } from 'firebase/firestore';
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import logo from '../images/images.png';
import QRCode from 'qrcode.react';

function AuthFormWorking({ formData }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false);
  const [enrollTOTP, setEnrollTOTP] = useState(false);
  const [totpSecret, setTotpSecret] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [loanAmount, setLoanAmount] = useState(500000);
  const [loanPurpose, setLoanPurpose] = useState('');
  const [creditScore, setCreditScore] = useState('');
  const [priority, setPriority] = useState('');
  const [timeframe, setTimeframe] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showSignUp, setShowSignUp] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectionError, setSelectionError] = useState('');
  const [loanApplicationId, setLoanApplicationId] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [yearlyProfit, setYearlyProfit] = useState('');
  const [formattedYearlyProfit, setFormattedYearlyProfit] = useState('');

  const loanOptions = [
    {
        company: "Bayfirst",
        timeframe: "Few days to get loan",
        options: [
          {
            name: "SBA Express Loan",
            documents: ["Past two year business tax return", "Most recent personal tax return", "Use of funds exact"],
            creditScore: "650",
            rate: "Prime + 2.75% (currently 11.25%)",
            useCase: "Working Capital, Equipment, Refinance",
            minAmount: 0,
            maxAmount: 150000 // Assuming no upper limit, adjust if needed
          }
        ]
      },

    {
      company: "LoanBud - SBA Boost",
      timeframe: "2-3 weeks to get the funds",
      options: [
        {
          name: "SBA Boost Loan",
          documents: ["Past two year business tax return", "Most recent personal tax return"],
          creditScore: "700",
          rate: "Prime + 2.75% (currently 11.25%)",
          useCase: "Working Capital, Equipment, Refinance",
          specialNote: "Revenue based loan option up to 150k even if tax returns show a loss",
          minAmount: 100000,
          maxAmount: 500000
        }
      ]
    },
    {
      company: "LoanBud - SBA Fast Track",
      timeframe: "2-3 weeks to get the funds",
      options: [
        {
          name: "SBA Fast Track",
          documents: [
            "Business Tax Returns - most recent 1 year up to $250k; most recent 3 years up to $350k",
            "Personal Tax Returns - most recent 1 year up to $250k; most recent 3 years up to $350k",
            "Business Debt Schedule",
            "Profit & Loss Statement (Interim)",
            "Drivers License or Passport",
            "Balance Sheet",
            "Personal Financial Statement (Form 413)",
            "SS-4 Letter from IRS confirming EIN",
            "SBA 1919 Form",
            "Articles of Incorporation / Organization",
            "Operating Agreement or Bylaws"
          ],
          creditScore: "675",
          rate: "Prime + 2.75% (currently 11.25%)",
          useCase: "Working Capital, Equipment, Refinance",
          minAmount: 100000,
          maxAmount: 350000
        },
        {
            name: "SBA Financing",
            documents: [
              "Business Tax Returns - most recent 1 year up to $250k; most recent 3 years for above $250k",
              "Personal Tax Returns - most recent 1 year up to $250k; most recent 3 years up to $250k",
              "Business Debt Schedule",
              "Profit & Loss Statement (Interim)",
              "Drivers License or Passport",
              "Balance Sheet",
              "Personal Financial Statement (Form 413)",
              "SS-4 Letter from IRS confirming EIN",
              "SBA 1919 Form",
              "Articles of Incorporation / Organization",
              "Operating Agreement or Bylaws"
            ],
            creditScore: "625",
            rate: "Prime + 2.75% (currently 11.25%)",
            useCase: "Working Capital, Equipment, Refinance, Startup",
            minAmount: 100000,
            maxAmount: 5000000
          }
      ]
    },
    {
        company: "Venture Debt LLC",
        timeframe: "Same day funding",
        options: [
          {
            name: "Equipment Financing Loan",
            documents: ["3 months bank statements", "Tax returns", "Invoice of equipment", "Application", "ID"],
            creditScore: "500",
            rate: "8-35%",
            useCase: "Equipment",
            minAmount: 0,
            maxAmount: Infinity // No upper limit
          },
          {
            name: "Working Capital Loan",
            documents: ["Application", "4 months bank statement", "ID", "Voided check"],
            creditScore: "500",
            rate: "8-35%",
            useCase: "Working Capital",
            minAmount: 0,
            maxAmount: Infinity // No upper limit
          },
          {
            name: "Refinace Loan",
            documents: ["Application", "4 months bank statement", "ID", "Voided check"],
            creditScore: "500",
            rate: "8-35%",
            useCase: "Refinance",
            minAmount: 0,
            maxAmount: Infinity // No upper limit
          }
        ]
      }
    
  ];

  const filterLoanOptions = useCallback(() => {
    console.log("Filtering loan options");
    const filtered = loanOptions.map(company => {
      const filteredOptions = company.options.filter(option => {
        const creditScoreMin = parseInt(option.creditScore);
        const userCreditScore = parseInt(creditScore);
        
        const creditScoreMatch = isNaN(creditScoreMin) || isNaN(userCreditScore) || userCreditScore >= creditScoreMin;
        const useCaseMatch = option.useCase.split(', ').some(useCase => 
          useCase.toLowerCase() === loanPurpose.toLowerCase()
        );
        const loanAmountMatch = loanAmount >= option.minAmount && loanAmount <= option.maxAmount;
        
        return creditScoreMatch && useCaseMatch && loanAmountMatch;
      });

      return {
        ...company,
        options: filteredOptions
      };
    }).filter(company => company.options.length > 0);

    return filtered;
  }, [loanOptions, creditScore, loanPurpose, loanAmount]);

  useEffect(() => {
    const filtered = filterLoanOptions();
    setFilteredOptions(filtered);
  }, [filterLoanOptions]);

  const handleSelectCard = (index) => {
    setSelectedCard(index);
  };

  const renderLoanOptions = () => {
    if (filteredOptions.length === 0) {
      return (
        <div className="text-center text-gray-600 text-xl">
          Sorry, there are no loan options that match your criteria at this time.
        </div>
      );
    }

    return (
      <div className="space-y-8">
        <div className="relative pt-4">
          <div className="overflow-x-auto pb-4 -mx-4 px-4">
            <div className="flex space-x-6 min-w-max pt-3">
              {filteredOptions.map((company, index) => (
                <div 
                  key={index} 
                  className={`flex-shrink-0 w-80 bg-white rounded-xl shadow-lg overflow-hidden border border-gray-200 transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105 cursor-pointer ${selectedCard === index ? 'ring-2 ring-blue-500' : ''}`}
                  onClick={() => handleSelectCard(index)}
                >
                  <div className="p-6 relative">
                    <div className="flex items-center justify-between mb-4">
                      <img src={logo} alt="Company Logo" className="h-8 w-auto" />
                    </div>
                    {company.options.map((option, optionIndex) => (
                      <div key={optionIndex} className="mb-4 last:mb-0">
                        <h4 className="text-lg font-semibold text-gray-800 mb-2">{option.name}</h4>
                        <div className="space-y-1">
                          <p className="text-sm text-gray-600">
                            <span className="font-medium">Credit Score:</span> {option.creditScore}+
                          </p>
                          {option.rate && (
                            <p className="text-sm text-gray-600">
                              <span className="font-medium">Rate:</span> {option.rate}
                            </p>
                          )}
                          {company.timeframe && (
                            <p className="text-sm text-gray-600">
                              <span className="font-medium">Timeline:</span> {company.timeframe}
                            </p>
                          )}
                          {option.useCase && (
                            <p className="text-sm text-gray-600">
                              <span className="font-medium">Use Case:</span> {option.useCase}
                            </p>
                          )}
                        </div>
                        {option.specialNote && (
                          <p className="text-sm text-blue-600 italic mt-2">{option.specialNote}</p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <p className="text-center text-gray-500">
          {filteredOptions.length > 3 ? "Scroll to see more options" : "Please select a loan option to proceed"}
        </p>
        {selectionError && (
          <p className="text-center text-red-500 mt-2">{selectionError}</p>
        )}
      </div>
    );
  };

  const sendSignUpNotification = async (userDetails) => {
    try {
      const emailRef = collection(db, 'email');
      
      const emailData = {
        to: "max@lended.ai",
        from: "max@sbacapital.ai",
        replyTo: "max@sbacapital.ai",
        subject: "New Sign Up - Working Capital",
        templateName: "SignUpNotification",
        message: {
          subject: "New Sign Up - Working Capital",
          html: `
            <p>Hello Max,</p>
            <p>A new user has signed up for Working Capital financing:</p>
            <ul>
              <li>Name: ${userDetails.firstName} ${userDetails.lastName}</li>
              <li>Email: ${userDetails.email}</li>
              <li>Loan Amount: $${userDetails.loanAmount}</li>
              <li>Phone Number: ${userDetails.phoneNumber}</li>
            </ul>
            <p>Best regards,<br>Lended AI System</p>
          `,
        },
      };

      await addDoc(emailRef, emailData);
      console.log('Sign-up notification email sent successfully');
    } catch (error) {
      console.error('Error sending sign-up notification email:', error);
    }
  };

  const handleEmailSignUp = async () => {
    if (!termsAccepted) {
      alert('You must agree to the terms of service.');
      return;
    }
    if (email && password && firstName && lastName && selectedCard !== null && phoneNumber) {
      try {
        setLoading(true);

        // Create user with email and password
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        console.log('User signed up:', user);

        // Create a user profile in 'users' node with 'Pre-lender' as the initial status
        const userProfileRef = doc(db, 'users', user.uid);
        await setDoc(userProfileRef, {
          email: user.email,
          firstName,
          lastName,
          phoneNumber, // Add phone number to user profile
          status: 'Pre-lender',
          lastUserUpdate: new Date(),
        });

        // Create a loan application with the user's name and selected loan details
        const selectedLoan = filteredOptions[selectedCard].options[0];
        
        // Determine the loan purpose
        let determinedLoanPurpose;
        if (loanPurpose === 'Startup') {
          determinedLoanPurpose = 'workingCapital-startup';
        } else if (selectedLoan.name === "Working Capital Loan" || selectedLoan.name === "Equipment Financing Loan") {
          determinedLoanPurpose = 'workingCapital-nonsba';
        } else {
          determinedLoanPurpose = 'workingCapital';
        }

        const loanApplicationRef = await addDoc(collection(db, 'loanApplications'), {
          userId: user.uid,
          firstName,
          lastName,
          email,
          phoneNumber, // Add phone number to loan application
          businessName: '',
          businessAge: '',
          businessState: '',
          loanPurpose: determinedLoanPurpose,
          loanSize: loanAmount.toString(),
          workingLoanType: selectedLoan.name,
          industry: '',
          capTable: [],
          ebitda: '',
          revenue: '',
          additionalNotes: '',
          status: 'Pre-lender',
          createdAt: new Date(),
          updatedAt: new Date(),
          personalGuarantors: [{ firstName, lastName }],
          realEstateAppraisal: '0',
          lastUserUpdate: new Date(),
          workingCapitalUse: '',
          creditScore: creditScore, // Add this line to save the credit score
          yearlyProfit: yearlyProfit,
        });

        // Create a reference to this application under the 'applications' node within the 'users' node
        const userApplicationsRef = doc(db, 'users', user.uid, 'applications', loanApplicationRef.id);
        await setDoc(userApplicationsRef, {
          applicationID: loanApplicationRef.id,
          status: 'Pre-lender',
        });

        console.log('User profile and loan application created');

        // Send notification email
        await sendSignUpNotification({
          firstName,
          lastName,
          email,
          loanAmount,
          phoneNumber
        });

        if (enrollTOTP) {
          // Send email verification if TOTP is enabled
          await sendEmailVerification(user);
          alert('A verification email has been sent to your email address. Please verify your email to complete the sign-up process.');
          setEmailSent(true);
        } else {
          // Directly navigate to the portal if TOTP is not enabled
          setTimeout(() => {
            setLoading(false);
            navigate('/portal', { state: { retryCount: 0 } });
          }, 2000);
        }

        setLoading(false);
      } catch (error) {
        console.log('Error signing up:', error);
        setLoading(false);

        if (error.code === 'auth/email-already-in-use') {
          alert('The email address is already in use. Please use a different email.');
        } else {
          alert('Error signing up. Please try again.');
        }
      }
    } else {
      alert('Please enter all required fields, including phone number.');
    }
  };

  // Function to handle email verification status check
  const checkEmailVerification = async () => {
    try {
      await auth.currentUser.reload();
      if (auth.currentUser.emailVerified) {
        console.log('Email verified');
        setEmailVerified(true);
        // Generate a TOTP secret
        const multiFactorSession = await multiFactor(auth.currentUser).getSession();
        const totpSecret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
        setTotpSecret(totpSecret);
      } else {
        alert('Please verify your email before proceeding.');
      }
    } catch (error) {
      console.log('Error checking email verification:', error);
      alert('Error verifying email. Please try again.');
    }
  };

  const handleTOTPEnrollment = async () => {
    try {
      // Finalize the enrollment
      const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
        totpSecret,
        verificationCode
      );
      await multiFactor(auth.currentUser).enroll(multiFactorAssertion, 'TOTP');
      console.log('TOTP enrollment successful');

      // Delay the navigation to the portal to allow data to be fully updated
      setTimeout(() => {
        setLoading(false);
        navigate('/portal', { state: { retryCount: 0 } });
      }, 2000); // Adjust the delay as needed
    } catch (error) {
      console.log('Error enrolling TOTP:', error);
      alert('Invalid verification code. Please try again.');
    }
  };

  const handleNextStep = () => {
    const newErrors = {};
    
    if (step === 0 && loanAmount < 25000) {
      newErrors.loanAmount = "Please select a loan amount of at least $25,000";
    } else if (step === 1) {
      if (!loanPurpose) newErrors.loanPurpose = "Please select a loan purpose";
    } else if (step === 2) {
      if (!creditScore) newErrors.creditScore = "Please enter your credit score";
      if (!yearlyProfit) newErrors.yearlyProfit = "Please enter your yearly profit";
    } else if (step === 3) {
      if (!priority) newErrors.priority = "Please select a priority";
      if (!timeframe) newErrors.timeframe = "Please select a timeframe";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      if (step < 3) {
        setStep(step + 1);
      } else {
        setShowOptions(true);
      }
    }
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 0 }).format(value);
  };
  const handleYearlyProfitChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    setYearlyProfit(value);
    setFormattedYearlyProfit(value ? formatCurrency(value) : '');
  };
  const renderProgressBar = () => {
    const progress = ((step + 1) / 4) * 100;
    return (
      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
        <div
          className="bg-blue-600 h-2.5 rounded-full transition-all duration-300 ease-in-out"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    );
  };

  const renderForm = () => {
    const handlePrevStep = () => {
      if (step > 0) {
        setStep(step - 1);
      }
    };

    const renderButtons = () => (
      <div className="flex justify-between space-x-4">
        {step > 0 && (
          <button
            onClick={handlePrevStep}
            className="flex-1 flex justify-center py-3 px-4 border border-gray-300 rounded-md shadow-sm text-lg font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
          >
            Previous
          </button>
        )}
        <button
          onClick={handleNextStep}
          className={`flex-1 flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out ${
            step === 0 ? 'w-full' : ''
          }`}
        >
          {step === 3 ? 'See Loan Options' : 'Next'}
        </button>
      </div>
    );

    const renderErrorMessage = (field) => {
      return errors[field] ? (
        <p className="text-red-500 text-sm mt-1">{errors[field]}</p>
      ) : null;
    };

    switch (step) {
      case 0:
        return (
          <div className="space-y-8">
            {renderProgressBar()}
            <h3 className="text-2xl font-semibold text-gray-900">How much are you looking for?</h3>
            <div className="mb-8">
              <label className="block text-lg font-medium text-gray-700 mb-2">Loan Amount: {formatCurrency(loanAmount)}</label>
              <div className="relative mt-2">
                <input
                  type="range"
                  min="50000"
                  max="5000000"
                  step="50000"
                  value={loanAmount}
                  onChange={(e) => setLoanAmount(Number(e.target.value))}
                  className="w-full h-3 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                />
                <div className="flex justify-between text-sm text-gray-600 mt-2">
                  <span>$50k</span>
                  <span>$5M</span>
                </div>
              </div>
              {renderErrorMessage('loanAmount')}
            </div>
            {renderButtons()}
          </div>
        );
      case 1:
        return (
          <div className="space-y-8">
            {renderProgressBar()}
            <h3 className="text-2xl font-semibold text-gray-900">What are you using the money for?</h3>
            <div className="space-y-4">
              {['Working Capital', 'Equipment', 'Refinance', 'Startup'].map((purpose) => (
                <div key={purpose} className="flex items-center">
                  <input
                    type="radio"
                    id={purpose}
                    name="loanPurpose"
                    value={purpose}
                    checked={loanPurpose === purpose}
                    onChange={(e) => setLoanPurpose(e.target.value)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                  />
                  <label htmlFor={purpose} className="ml-3 block text-lg font-medium text-gray-700">
                    {purpose}
                  </label>
                </div>
              ))}
            </div>
            {renderErrorMessage('loanPurpose')}
            {renderButtons()}
          </div>
        );
      case 2:
        return (
          <div className="space-y-8">
            {renderProgressBar()}
            <h3 className="text-2xl font-semibold text-gray-900">What is your credit score?</h3>
            <input
              type="number"
              value={creditScore}
              onChange={(e) => setCreditScore(e.target.value)}
              className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-lg"
              placeholder="Enter credit score"
            />
            {renderErrorMessage('creditScore')}
            <h3 className="text-2xl font-semibold text-gray-900">What is your yearly profit?(Enter 0 if a startup)</h3>
            <div className="relative">
              <input
                type="text"
                value={formattedYearlyProfit}
                onChange={handleYearlyProfitChange}
                className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-lg pl-8"
                placeholder="Enter yearly profit"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-lg">$</span>
              </div>
            </div>
            {renderErrorMessage('yearlyProfit')}
            {renderButtons()}
          </div>
        );
      case 3:
        return (
          <div className="space-y-8">
            {renderProgressBar()}
            <h3 className="text-2xl font-semibold text-gray-900">What is most important to you?</h3>
            <select
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
              className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-lg"
            >
              <option value="">Select priority</option>
              <option value="cost">Cost</option>
              <option value="speed">Speed of funds</option>
            </select>
            {renderErrorMessage('priority')}
            <h3 className="text-2xl font-semibold text-gray-900">How fast do you need the money?</h3>
            <select
              value={timeframe}
              onChange={(e) => setTimeframe(e.target.value)}
              className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-lg"
            >
              <option value="">Select timeframe</option>
              <option value="immediate">Immediately</option>
              <option value="week">Within a week</option>
              <option value="month">Within a month</option>
            </select>
            {renderErrorMessage('timeframe')}
            {renderButtons()}
          </div>
        );
      default:
        return null;
    }
  };

  const renderSignUpForm = () => {
    return (
      <div className="space-y-6">
        <div className="space-y-6">
          <div className="flex space-x-4">
            <div className="w-1/2">
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="First Name"
              />
            </div>
            <div className="w-1/2">
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Last Name"
              />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="Email address"
            />
          </div>
          <div>
            <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
              Phone Number
            </label>
            <input
              type="tel"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="Phone Number"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="Password"
            />
          </div>
          <div className="flex items-center">
            <input
              id="enrollTOTP"
              name="enrollTOTP"
              type="checkbox"
              checked={enrollTOTP}
              onChange={(e) => setEnrollTOTP(e.target.checked)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="enrollTOTP" className="ml-2 block text-sm text-gray-900">
              Enable Two-Factor Authentication using Google Authenticator (TOTP)
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="termsAccepted"
              name="termsAccepted"
              type="checkbox"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="termsAccepted" className="ml-2 block text-sm text-gray-900">
              I agree to the <button type="button" onClick={() => setShowTerms(true)} className="text-blue-600 underline">terms of service</button>
            </label>
          </div>
          <div>
            <button
              type="button"
              onClick={handleEmailSignUp}
              disabled={loading}
              className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                loading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              }`}
            >
              {loading ? 'Creating account...' : 'Create an account'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleContinueToApplication = () => {
    if (selectedCard === null) {
      setSelectionError('Please select a loan option before continuing.');
    } else {
      setSelectionError('');
      setShowSignUp(true);
      setShowOptions(false);
    }
  };

  useEffect(() => {
    const fetchLoanApplication = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          if (userData.applications && userData.applications.length > 0) {
            setLoanApplicationId(userData.applications[0].applicationID);
          }
        }
      }
    };

    fetchLoanApplication();
  }, []);

  const getAISuggestion = (option, company) => {
    // Immediately return false if the option is Equipment Financing Loan
    if (option.name === "Equipment Financing Loan") {
      return false;
    }
    if (option.name === "Working Capital Loan") {
        return false;
      }
    // Check if the option is an SBA Bolt Loan
    if (option.name === "SBA Bolt Loan") {
      return true;
    }
    
    // If it's not an SBA Bolt Loan, use the previous logic
    if (timeframe === 'immediate' && company.timeframe && company.timeframe.toLowerCase().includes('same day')) {
      return true;
    }
    if (timeframe === 'week' && company.timeframe && company.timeframe.toLowerCase().includes('few days')) {
      return true;
    }
    if (priority === 'cost' && option.rate && parseFloat(option.rate) <= 11.25) {
      return true;
    }
    if (priority === 'speed' && company.timeframe && (company.timeframe.toLowerCase().includes('same day') || company.timeframe.toLowerCase().includes('few days'))) {
      return true;
    }
    return false;
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="w-full max-w-6xl bg-white rounded-2xl shadow-xl overflow-hidden">
        {showOptions ? (
          <div className="p-8">
            <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">Loan Options You Qualify For</h2>
            {renderLoanOptions()}
            <div className="mt-8 text-center">
              <button
                onClick={handleContinueToApplication}
                className="px-6 py-3 bg-blue-600 text-white rounded-md text-lg font-medium hover:bg-blue-700 transition duration-150 ease-in-out"
              >
                Continue to Application
              </button>
            </div>
          </div>
        ) : showSignUp ? (
          <div className="p-8">
            <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">Create Your Account</h2>
            {renderSignUpForm()}
          </div>
        ) : (
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 bg-blue-600 p-12 text-white flex flex-col justify-center">
              <h2 className="text-4xl font-bold mb-4">Loan Information</h2>
              <p className="text-xl mb-8">Find out if you qualify in seconds</p>
              <div className="bg-white bg-opacity-20 rounded-lg p-6">
                <p className="text-2xl font-semibold mb-2">Loan Amount</p>
                <p className="text-4xl font-bold">{formatCurrency(loanAmount)}</p>
              </div>
            </div>
            <div className="w-full md:w-1/2 p-12">
              <div className="mb-8">
                <img
                  className="h-12 w-auto mb-6"
                  src={logo}
                  alt="Your Company"
                />
                <h2 className="text-3xl font-bold text-gray-900 mb-2">
                  {step < 3 ? "Apply Now" : "Create your account"}
                </h2>
                <p className="text-lg text-gray-600">
                  {step < 3 ? "Please provide some information about your loan needs" : "Create an account to apply for working capital financing."}
                </p>
              </div>
              {renderForm()}
            </div>
          </div>
        )}
      </div>
      {showTerms && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full">
            <h2 className="text-2xl font-bold mb-6">Terms of Service</h2>
            <div className="text-base text-gray-700 space-y-4">
              <p>Effective Date: {new Date().toLocaleDateString()}</p>
              <p>
                By using our services, you agree that we may receive referral compensation for matching you with a bank. This compensation helps us maintain and improve our services.
              </p>
              <p>
                We are committed to providing you with the best possible experience and will always strive to act in your best interest.
              </p>
              <p>
                For any questions or concerns regarding these terms, please contact:
              </p>
              <p>
                Max Krakow, CEO<br />
                Phone: (914) 319-7160
              </p>
            </div>
            <div className="mt-8 flex justify-end">
              <button
                type="button"
                onClick={() => setShowTerms(false)}
                className="bg-blue-600 text-white px-6 py-3 rounded-md text-lg font-medium hover:bg-blue-700 transition duration-150 ease-in-out"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AuthFormWorking;
