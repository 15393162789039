// AuthForm.js
import React, { useState } from 'react';
import { auth, db } from '../../firebase';
import { createUserWithEmailAndPassword, multiFactor, TotpMultiFactorGenerator, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import logo from '../images/images.png';
import QRCode from 'qrcode.react';

function AuthFormSearcher({ formData }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(''); // New state for phone number
  const [loading, setLoading] = useState(false);
  const [enrollTOTP, setEnrollTOTP] = useState(false);
  const [totpSecret, setTotpSecret] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const navigate = useNavigate();

  const sendSignUpNotification = async (userDetails) => {
    try {
      const emailRef = collection(db, 'email');
      
      const emailData = {
        to: "max@lended.ai",
        from: "max@sbacapital.ai",
        replyTo: "max@sbacapital.ai",
        subject: "New Sign Up - Searcher",
        templateName: "SignUpNotification",
        message: {
          subject: "New Sign Up - Searcher",
          html: `
            <p>Hello Max,</p>
            <p>A new user has signed up as a Searcher:</p>
            <ul>
              <li>Name: ${userDetails.firstName} ${userDetails.lastName}</li>
              <li>Email: ${userDetails.email}</li>
              <li>Phone Number: ${userDetails.phoneNumber}</li>
            </ul>
            <p>Best regards,<br>Lended AI System</p>
          `,
        },
      };

      await addDoc(emailRef, emailData);
      console.log('Sign-up notification email sent successfully');
    } catch (error) {
      console.error('Error sending sign-up notification email:', error);
    }
  };

  const handleEmailSignUp = async () => {
    if (!termsAccepted) {
      alert('You must agree to the terms of service.');
      return;
    }
    if (email && password && firstName && lastName && phoneNumber) {
      try {
        setLoading(true);

        // Create user with email and password
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        console.log('User signed up:', user);

        // Create a user profile in 'users' node with 'Pre-lender' as the initial status
        const userProfileRef = doc(db, 'users', user.uid);
        await setDoc(userProfileRef, {
          email: user.email,
          firstName,
          lastName,
          phoneNumber,
          status: 'Pre-lender',
          lastUserUpdate: new Date(),
        });

        // Create an empty loan application with the user's name
        const loanApplicationRef = await addDoc(collection(db, 'loanApplications'), {
          userId: user.uid,
          firstName,
          lastName,
          email,
          phoneNumber,
          businessName: '',
          businessAge: '',
          businessState: '',
          loanSize: '',
          industry: '',
          capTable: [],
          ebitda: '',
          revenue: '',
          additionalNotes: '',
          status: 'Pre-lender',
          createdAt: new Date(),
          updatedAt: new Date(),
          personalGuarantors: [{ firstName, lastName }],
          realEstateAppraisal: '0',
          lastUserUpdate: new Date(),
          loanPurpose: 'Acquisition',
        });

        // Create a reference to this application under the 'applications' node within the 'users' node
        const userApplicationsRef = doc(db, 'users', user.uid, 'applications', loanApplicationRef.id);
        await setDoc(userApplicationsRef, {
          applicationID: loanApplicationRef.id,
          status: 'Pre-lender',
        });

        console.log('User profile and empty loan application created');

        // Send notification email
        await sendSignUpNotification({
          firstName,
          lastName,
          email,
          phoneNumber
        });

        if (enrollTOTP) {
          // Send email verification if TOTP is enabled
          await sendEmailVerification(user);
          alert('A verification email has been sent to your email address. Please verify your email to complete the sign-up process.');
          setEmailSent(true);
        } else {
          // Directly navigate to the portal if TOTP is not enabled
          setTimeout(() => {
            setLoading(false);
            navigate('/portal', { state: { retryCount: 0 } });
          }, 2000);
        }

        setLoading(false);
      } catch (error) {
        console.log('Error signing up:', error);
        setLoading(false);

        if (error.code === 'auth/email-already-in-use') {
          alert('The email address is already in use. Please use a different email.');
        } else {
          alert('Error signing up. Please try again.');
        }
      }
    } else {
      alert('Please enter all required fields.');
    }
  };

  // Function to handle email verification status check
  const checkEmailVerification = async () => {
    try {
      await auth.currentUser.reload();
      if (auth.currentUser.emailVerified) {
        console.log('Email verified');
        setEmailVerified(true);
        // Generate a TOTP secret
        const multiFactorSession = await multiFactor(auth.currentUser).getSession();
        const totpSecret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
        setTotpSecret(totpSecret);
      } else {
        alert('Please verify your email before proceeding.');
      }
    } catch (error) {
      console.log('Error checking email verification:', error);
      alert('Error verifying email. Please try again.');
    }
  };

  const handleTOTPEnrollment = async () => {
    try {
      // Finalize the enrollment
      const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
        totpSecret,
        verificationCode
      );
      await multiFactor(auth.currentUser).enroll(multiFactorAssertion, 'TOTP');
      console.log('TOTP enrollment successful');

      // Delay the navigation to the portal to allow data to be fully updated
      setTimeout(() => {
        setLoading(false);
        navigate('/portal', { state: { retryCount: 0 } });
      }, 2000); // Adjust the delay as needed
    } catch (error) {
      console.log('Error enrolling TOTP:', error);
      alert('Invalid verification code. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-blue-900 flex items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8 bg-white p-10 rounded-xl shadow-2xl">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src={logo}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Create your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Create an account to get a quick pre-check on your financials, enabling you to receive term sheets within hours when you find a business.
          </p>
        </div>
        {!emailSent ? (
          <div className="space-y-6">
            <div className="rounded-md shadow-sm space-y-4">
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <label htmlFor="firstName" className="sr-only">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="First Name"
                  />
                </div>
                <div className="w-1/2">
                  <label htmlFor="lastName" className="sr-only">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="phoneNumber" className="sr-only">
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Phone Number"
                />
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
              <div className="flex items-center">
                <input
                  id="enrollTOTP"
                  name="enrollTOTP"
                  type="checkbox"
                  checked={enrollTOTP}
                  onChange={(e) => setEnrollTOTP(e.target.checked)}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="enrollTOTP" className="ml-2 block text-sm text-gray-900">
                  Enable Two-Factor Authentication using Google Authenticator (TOTP)
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="termsAccepted"
                  name="termsAccepted"
                  type="checkbox"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="termsAccepted" className="ml-2 block text-sm text-gray-900">
                  I agree to the <button type="button" onClick={() => setShowTerms(true)} className="text-blue-600 underline">terms of service</button>
                </label>
              </div>
              <div>
                <button
                  type="button"
                  onClick={handleEmailSignUp}
                  disabled={loading}
                  className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                    loading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                  }`}
                >
                  {loading ? 'Creating account...' : 'Create an account'}
                </button>
              </div>
            </div>
          </div>
        ) : enrollTOTP && !emailVerified ? (
          <div className="space-y-6">
            <h3 className="text-center text-xl font-bold tracking-tight text-gray-900">
              Check your email to verify your account.
            </h3>
            <p className="text-center text-sm text-gray-600">
              After verifying your email, click the button below to complete the registration process.
            </p>
            <div>
              <button
                type="button"
                onClick={checkEmailVerification}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Once you have verified your email click here
              </button>
            </div>
          </div>
        ) : enrollTOTP && totpSecret ? (
          <div>
            <h3 className="mt-6 text-center text-xl font-bold tracking-tight text-gray-900">
              Two-Factor Authentication Setup
            </h3>
            <div className="mt-4">
              <ul className="list-disc list-inside text-sm text-gray-500">
                <li>Download the Google Authenticator app on your phone</li>
                <li>Click the + in the bottom right corner and scan the QR code below with your authenticator app or enter the secret key manually.</li>
                <li>Input the verification code displayed in the app</li>
              </ul>
              <div className="mt-4 flex justify-center">
                <QRCode value={totpSecret.generateQrCodeUrl(email, 'Your App Name')} />
              </div>
              <div className="mt-4">
                <label htmlFor="secretKey" className="block text-sm font-medium text-gray-700">
                  Secret Key
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="secretKey"
                    value={totpSecret.secretKey}
                    readOnly
                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label htmlFor="verificationCode" className="block text-sm font-medium text-gray-700">
                  Verification Code
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="verificationCode"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="Enter the code from your authenticator app"
                  />
                </div>
              </div>
              <div className="mt-6">
                <button
                  type="button"
                  onClick={handleTOTPEnrollment}
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Verify and Enroll
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="space-y-6">
            <h3 className="text-center text-xl font-bold tracking-tight text-gray-900">
              Registration Complete
            </h3>
            <p className="text-center text-sm text-gray-600">
              Your account has been successfully verified and set up. You can now access the portal.
            </p>
            <div>
              <button
                type="button"
                onClick={() => navigate('/portal', { state: { retryCount: 0 } })}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Go to Portal
              </button>
            </div>
          </div>
        )}
      </div>
      {showTerms && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Terms of Service</h2>
            <div className="text-sm text-gray-600 space-y-2">
              <p>Effective Date: {new Date().toLocaleDateString()}</p>
              <p>
                By using our services, you agree that we may receive referral compensation for matching you with a bank. This compensation helps us maintain and improve our services.
              </p>
              <p>
                We are committed to providing you with the best possible experience and will always strive to act in your best interest.
              </p>
              <p>
                Your information will only be shared with potential lenders and nobody else. We respect your privacy and maintain strict confidentiality of your data.
              </p>
              <p>
                For any questions or concerns regarding these terms, please contact:
              </p>
              <p>
                Max Krakow, CEO<br />
                Phone: (914) 319-7160
              </p>
            </div>
            <div className="mt-4 flex justify-end">
              <button
                type="button"
                onClick={() => setShowTerms(false)}
                className="bg-blue-600 text-white px-4 py-2 rounded-md"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AuthFormSearcher;
